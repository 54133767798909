<template>
  <div>
    <p class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4">
      {{ risque.name }}
    </p>
    <p
      v-for="(definition, index) in risque.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
    </p>
    <div v-for="(sub, idx) in risque.sub_title" :key="'sub' + idx" class="py-6">
      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4"
      >
        {{ sub.title }}
      </p>
      <p
        v-show="sub.value != 'Non'"
        v-for="(descr, idx) in sub.descrs"
        :key="'descr' + idx"
        class="text-promy-gray-600 font-arial sm:text-sm py-4"
      >
        {{ descr }}
      </p>
      <div
        v-if="sub.value != 'Non' && (sub.id === 'TRI' || sub.key === 'ppr')"
        class="w-full flex md:flex-col justify-start items-start"
      >
        <div
          class="w-2/5 h-64 border-2 flex md:w-full justify-center items-center"
        >
          <Map
            :id_map="`map_inn_${idx}`"
            :layer="sub.layer"
            :rayon="1000"
            ref="map"
            v-if="active"
          />
        </div>
        <img
          v-if="sub.key === 'ppr'"
          :src="`https://mapsref.brgm.fr/wxs/georisques/risques?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=${sub.layer}`"
          class="ml-6 md:ml-0 md:mt-4"
        />
        <div class="flex items-center ml-4 space-x-2" v-if="sub.id === 'TRI'">
          <div
            style="background-color: #b7e7bf; border-color: #51a292"
            class="w-10 h-5 border-2"
          ></div>
          <span class="text-sm"> TRI</span>
        </div>
      </div>
      <div v-if="sub.id === 'TRI' && sub.value != 'Non'">
        <p
          class="
            text-promy-gray-700
            font-arial font-bold
            text-lg
            sm:text-sm
            pt-6
            pb-4
          "
        >
          {{ sub.tableTitle }}
        </p>
        <TablesRisques :headers="sub.tableHeaders" :body="sub.bodyTable" />
      </div>
      <div v-if="sub.id === 'PAPI' && sub.value != 'Non'">
        <p
          class="
            text-promy-gray-700
            font-arial font-bold
            text-lg
            sm:text-sm
            pt-6
            pb-4
          "
        >
          {{ sub.tableTitle }}
        </p>
        <TablesRisques :headers="sub.tableHeaders" :body="sub.bodyTable" />
      </div>

      <div v-if="sub.key === 'ppr' && sub.value != 'Non'" class="w-full">
        <p
          class="
            text-promy-gray-700
            font-arial font-bold
            text-lg
            sm:text-sm
            pt-6
            pb-4
          "
        >
          Arrêtés
        </p>
        <TablesRisques
          :keyTable="sub.key"
          :headers="sub.tableHeaders"
          :body="sub.bodyTable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Map from './map.vue'
import risques from '@/mixins/risques'
import helpers from '@/mixins/helpers'
import TablesRisques from './TablesRisques'
export default {
  mixins: [risques, helpers],

  props: {
    risque: {
      type: Object,
      require: true,
    },
    active: {
      type: Boolean,
      require: true,
    },
  },

  components: {
    Map,
    TablesRisques,
  },

  data() {
    return {
      pprn_info: [],
      HeadersArretes: ['PPRN', 'Aléa', 'Approuvé le'],
    }
  },

  created() {
    this.risque.sub_title = []
    this.setDetailRisque()

    this.risque.sub_title.forEach((sub) => {
      if (sub.key === 'alea') {
        if (sub.id === 'TRI') {
          sub.value = this.notEmptyObject(this.risques.tri) ? 'Oui' : 'Non'
          sub.bodyTable = this.setValuesTable(
            this.risques.tri,
            'dateArreteNat',
            'libelleTri',
            'listLibRisqueLong',
            'listLibCoursDeau',
            'dateArretePcbTri',
          )
        } else if (sub.id === 'PAPI') {
          sub.value = this.notEmptyObject(this.risques.papi) ? 'Oui' : 'Non'
          sub.bodyTable = this.setValuesTable(
            this.risques.papi,
            ['codeNatPapi', 'libellePapi'],
            'listLibRisqueLong',
            'dateLabellisation',
            'dateSignature',
            'dateFinRealisation',
          )
        }
      } else if (sub.key === 'ppr') {
        this.prepareDataPPR(sub, '11')
      }
    })
  },

  methods: {
    setDetailRisque() {
      this.risques.definitions_risques.INN.alea.forEach((inn) => {
        let ids = ['TRI', 'PAPI']
        if (ids.includes(inn.id)) this.setObj('alea', inn)
      })
      this.setObj('ppr', this.risques.definitions_risques.INN.ppr)
    },

    setObj(key, obj) {
      let alea_obj = {
        key: key,
        id: obj.id,
        label: obj.label,
        title: obj.title,
        tableTitle: obj.tableTitle,
        descrs: this.setDescriptions(obj),
        properties: obj.properties ? obj.properties.toString() : null,
        layer: key === 'ppr' ? obj.layer : obj.layer[0],
        value: '',
        codes: key === 'ppr' ? obj.codes : [],
        tableHeaders: obj.table ? obj.table.headers : this.HeadersArretes,
      }
      this.risque.sub_title.push(alea_obj)
    },

    setDescriptions(obj) {
      let descrs = []
      descrs.push(obj.descr)
      for (let i = 0; i < 5; i++) {
        if (obj['descr' + i]) descrs.push(obj['descr' + i])
      }
      return descrs
    },
  },

  computed: {
    ...mapGetters({
      risques: 'address/risques',
    }),
  },
}
</script>

<style lang="scss" scoped></style>
