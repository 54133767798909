<template>
  <div class="w-full h-full flex flex-1 z-10" :id="id_map"></div>
</template>

<script>
import { mapGetters } from 'vuex'
import helpers from '@/mixins/helpers'

export default {
  mixins: [helpers],

  props: {
    id_map: {
      type: String,
      required: true,
    },
    layer: {
      type: String,
      required: true,
    },
    opacity: {
      type: Number,
      default: 1,
    },
    geo_value: {
      type: String,
      default: 'georisques',
    },
    rayon: {
      type: Number,
      default: 500,
    },
    domaine_wms: {
      type: String,
      default: 'mapsref',
    },
  },

  data() {
    return {
      map: null,
    }
  },
  computed: {
    ...mapGetters({
      informations_gov: 'address/address',
    }),
  },
  mounted() {
    this.initMap()
  },

  methods: {
    initMap() {
      let center = this.informations_gov.information.geometry.coordinates
      this.map = L.map(this.id_map, {
        zoomControl: false,
      }).setView([center[1], center[0]], 20)
      this.map.scrollWheelZoom.disable()
      this.map.dragging.disable()
      var layer = L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${this.$key_mapbox}`,
        {
          attribution: '<Imagery © <a href="https://mapbox.com">Mapbox</a>',
          maxZoom: 21,
          maxNativeZoom: 21,
        },
      ).addTo(this.map)

      setTimeout(() => {
        let url_wms =
          this.layer === 'Zonage_sismique_Communes'
            ? 'http://ws.carmencarto.fr/WMS/168/zonage_sismique'
            : `http://mapsref.brgm.fr/wxs/${this.geo_value}/risques`
        this.map.invalidateSize()
        var sismique = L.tileLayer.wms(url_wms, {
          layers: this.layer,
          format: 'image/png',
          transparent: true,
          attribution:
            '(c)<a href="http://kartoza.com">Kartoza.com</a> and <a href="http://www.ngi.gov.za/">SA-NGI</a>',
          opacity: 0.7,
        })
        sismique.addTo(this.map).bindPopup('Legende gonfelement d argile')

        this.addMarkerParcelle(center)
        this.fitBoundsRayon(center)
      }, 900)
    },
    fitBoundsRayon(center) {
      var point = this.$turf.point(center.map((coord) => parseFloat(coord)))
      var buffered = this.$turf.buffer(point, this.rayon, {
        units: 'meters',
      })
      var bbox = this.$turf.bbox(buffered)
      this.map.fitBounds([
        [bbox[1], bbox[0]],
        [bbox[3], bbox[2]],
      ])
    },
    addMarkerParcelle(center) {
      var icon_marker = L.icon({
        iconUrl: '/images/GU/markers/terrain-marker-pink.svg',
        iconSize: [35, 44],
        iconAnchor: [20, 40],
      })
      L.marker([center[1], center[0]], {
        icon: icon_marker,
      }).addTo(this.map)
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove()
    }
  },
}
</script>
<style lang="scss">
.leaflet-control-attribution {
  display: none !important;
}
</style>
