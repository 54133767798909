import { mapGetters } from 'vuex'
export default {
  methods: {
    prepareDataPPR(sub, code_risque) {
      let pprn_info = [];
      if (this.notEmptyObject(this.risques.ppr)) {
        this.risques.ppr.forEach((item) => {
          if (item.code_risque === code_risque) {
            sub.value = 'Oui'
            pprn_info.push(item)
          }
        })
        sub.value = sub.value === 'Oui' ? 'Oui' : 'Non'
        sub.bodyTable = this.setValuesTable(
          pprn_info,
          ['codeNatPpr', 'libellePpr'],
          'listRisques',
          'dateApprobation',
        )
      } else {
        sub.value = 'Non'
      }
    },
    setValuesTable(data, ...keys) {
      let result = []
      data.forEach((val) => {
        let obj = []
        keys.forEach((key) => {
          let valToPush
          if (typeof key !== 'string') {
            valToPush =
              this.checkKey(val, key[0]) + ' - ' + this.checkKey(val, key[1])
          } else {
            valToPush = this.checkKey(val, key)
          }
          obj.push(valToPush)
        })

        result.push(obj)
      })
      return result
    },

    checkKey(val, key) {
      return val[key] === undefined ? val['properties'][key] : val[key]
    },

    getAllFeatures(
      refs_cadastrale_parcelles_voisines = this
        .refs_cadastrale_parcelles_voisines,
    ) {
      let features = this.address_cadastre.geospatial.features
      let features_autour = this.address_cadastre.geospatial_autour.features
      let features_autour_exist = []

      features_autour.forEach((feature_autour) => {
        if (refs_cadastrale_parcelles_voisines)
          if (
            refs_cadastrale_parcelles_voisines.includes(
              feature_autour.parcelle.id,
            )
          ) {
            features_autour_exist.push(feature_autour)
          }
      })
      let all_features = [features, ...features_autour_exist]

      return all_features
    },

    getAllParcelles(all_features) {
      let all_parcelles = all_features.map(function (feature) {
        return feature.parcelle
      })
      return all_parcelles
    },

    setRisquesByAdress(risques) {
      this.$store.commit('address/SET_DETAILS_RISQUES', [])
      this.$store.commit('address/RISQUES', risques)
      try {
        this.insertRisquesDefinitions(risques)
      } catch (e) {
        console.log('donnée des risques insuffisant')
      }
      this.$store.commit('address/RISQUES_IS_LOADING', false)
    },

    insertRisquesDefinitions(risques) {
      Object.keys(risques.definitions_risques).forEach((key_risque) => {
        let definitions = []
        let definitions_key = [
          'definitionPol',
          'definition2Lien',
          'definition',
          'definition2',
          'definition3',
        ]
        Object.keys(risques.definitions_risques[key_risque]).forEach(
          (key_defini) => {
            if (definitions_key.includes(key_defini))
              definitions.push(
                risques.definitions_risques[key_risque][key_defini],
              )
          },
        )
        let risque_obj = {
          key: key_risque,
          name: risques.definitions_risques[key_risque].name,
          definitions: definitions,
          sub_title: [],
        }
        this.insertDetailsRisque({
          obj: risques,
          key_risque: key_risque,
          key: 'alea',
          risque_obj: risque_obj,
        })
        this.insertDetailsRisque({
          obj: risques,
          key_risque: key_risque,
          key: 'ppr',
          risque_obj: risque_obj,
        })

        this.$store.commit('address/SET_DETAILS_RISQUES', risque_obj)
      })
    },

    insertDetailsRisque(params) {
      if (params.obj.definitions_risques[params.key_risque][params.key]) {
        let alea
        if (params.key === 'alea') {
          alea =
            params.obj.definitions_risques[params.key_risque][params.key][0]
        } else {
          if (!params.obj.definitions_risques[params.key_risque][params.key])
            return
          alea = params.obj.definitions_risques[params.key_risque][params.key]
        }
        let alea_obj = {
          key: params.key,
          title: alea.title,
          label: alea.label,
          rayon: alea.rayon,
          layer: alea.layer[0],
          properties: alea.properties ? alea.properties.toString() : '',
          value: '',
          descr: alea.descr,
          codes: params.key === 'ppr' ? alea.codes : [],
        }
        params.risque_obj.sub_title.push(alea_obj)
      }
    },
  },

  computed: {
    ...mapGetters({
      address_cadastre: 'address/addressCadastre',
      refs_cadastrale_parcelles_voisines:
        'address/getRefsCadastraleParcellesVoisines',
    }),
  },
}
